import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import './animations.css';

const WalletChecker = () => {
    const [wallets, setWallets] = useState([]);
    const [input, setInput] = useState('');
    const [message, setMessage] = useState('');
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [fadeOutConfetti, setFadeOutConfetti] = useState(false);
    const [showInfoBox, setShowInfoBox] = useState(false);

    const CONFETTI_DISPLAY_TIME = 4000;
    const CONFETTI_FADE_OUT_TIME = 1000;

    useEffect(() => {
        let isMounted = true; // Track if component is mounted

        fetch('/wallets.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (isMounted) setWallets(data);
            })
            .catch(error => console.error('Error loading wallet addresses:', error));

        return () => {
            isMounted = false; // Cleanup on unmount
        };
    }, []);

    const handleInputChange = (e) => {
        setInput(e.target.value);
        setShowInfoBox(false);
    };

    const getWalletMessage = (wallet) => {
        if (wallet.mintNumber === 3 && wallet.monsterNFTs === 6 && wallet.isMonsterHolder === true) {
            return 'Your wallet is on the VIP WL, and you can mint 1 monster for free and 3 monsters in the general WL';
        } else if (wallet.mintNumber === 3 && wallet.monsterNFTs === 0 && wallet.isMonsterHolder === true) {
            return 'Yeah, you are a holder of a monster on Polygon and you are whitelisted to mint up to 3 monsters on Scroll.';
        } else if (wallet.mintNumber === 3 && wallet.monsterNFTs === 0 && wallet.isMonsterHolder === false) {
            return 'Yeah, you are whitelisted. You can mint up to 3 monsters on Scroll.';
        } else if (wallet.mintNumber === 3 && wallet.monsterNFTs === 6 && wallet.isMonsterHolder && !wallet.isDemonRollHolder) {
            return 'Yeah, you hold 5 or more monsters on Polygon. You can mint 1 monster on Scroll and you are whitelisted to mint up to 3 monsters.';
        } else {
            return `Yes, your wallet is on the list and you can mint up to ${wallet.mintNumber} NFTs on mint day.`;
        }
    };

    const checkWallet = () => {
        if (input.trim() === '') {
            setMessage('The input is empty. Please enter your wallet address.');
            setIsError(true);
            setShowAlert(true);
            setShowInfoBox(false);
            return;
        }

        setIsLoading(true); // Start loading
        setTimeout(() => {
            const wallet = wallets.find(w => w.address.toLowerCase() === input.toLowerCase());
            if (wallet) {
                const message = getWalletMessage(wallet);
                setMessage(message);
                setIsError(false);
                setShowConfetti(true); // Show confetti
                setTimeout(() => {
                    setFadeOutConfetti(true); // Start fade out
                    setTimeout(() => {
                        setShowConfetti(false); // Hide confetti after fade out
                        setFadeOutConfetti(false); // Reset fade out state
                    }, CONFETTI_FADE_OUT_TIME); // Duration of fade out
                }, CONFETTI_DISPLAY_TIME); // Duration before fade out starts (4 seconds)
                setShowInfoBox(false);
            } else {
                setMessage('Sorry, you are not on the list.');
                setIsError(true);
                setShowInfoBox(true);
            }
            setShowAlert(true);
            setIsLoading(false); // Stop loading
        }, 2000); // Simulating a delay for the loading spinner
    };

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
        if (!isDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };

    return ( <
        div className = { `min-h-screen flex flex-col items-center justify-center p-4 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}` } > {
            showConfetti && ( <
                div className = { fadeOutConfetti ? 'fade-out' : '' } >
                <
                Confetti width = { window.innerWidth }
                height = { window.innerHeight }
                /> < /
                div >
            )
        } <
        div className = "bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200 p-6 md:p-8 rounded-lg shadow-lg w-full max-w-3xl flex flex-col font-chakra" >
        <
        img src = "alpha.png"
        alt = "Banner"
        className = "w-full h-32 md:h-48 object-cover rounded-lg mb-4 md:mb-6" / >
        <
        div className = "flex flex-col md:flex-row justify-between items-center mb-4 md:mb-6" >
        <
        h1 className = "text-2xl md:text-3xl font-bold text-center text-gray-900 dark:text-gray-100 mb-4 md:mb-0" > Wallet Checker < /h1> <
        button onClick = { toggleDarkMode }
        className = "bg-gray-700 dark:bg-gray-300 text-white dark:text-gray-900 py-2 px-4 rounded-md shadow-md transition-transform transform hover:scale-105" > { isDarkMode ? 'Light Mode' : 'Dark Mode' } <
        /button> < /
        div > <
        input type = "text"
        value = { input }
        onChange = { handleInputChange }
        placeholder = "Enter your wallet address"
        className = "w-full px-4 py-3 border rounded-md mb-4 md:mb-6 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-100 dark:border-gray-600" /
        >
        <
        button onClick = { checkWallet }
        className = "w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition-colors shadow-md" > {
            isLoading ? ( <
                div className = "flex justify-center items-center" >
                <
                svg className = "animate-spin h-5 w-5 text-white"
                xmlns = "http://www.w3.org/2000/svg"
                fill = "none"
                viewBox = "0 0 24 24" >
                <
                circle className = "opacity-25"
                cx = "12"
                cy = "12"
                r = "10"
                stroke = "currentColor"
                strokeWidth = "4" > < /circle> <
                path className = "opacity-75"
                fill = "currentColor"
                d = "M4 12a8 8 0 018-8V0c-5.523 0-10 4.477-10 10h2zm2 5.291A7.962 7.962 0 014 12H2c0 3.042 1.135 5.824 3 7.938l1-1.647z" > < /path> < /
                svg > <
                /div>
            ) : (
                'Check'
            )
        } <
        /button>

        {
            showAlert && ( <
                div role = "alert"
                className = { `rounded-md border-l-4 p-4 mt-4 md:mt-6 ${isError ? 'border-red-500 bg-red-50' : 'border-green-500 bg-green-50'} animate-slide-in shadow-md` } >
                <
                div className = { `flex items-center gap-3 ${isError ? 'text-red-800' : 'text-green-800'}` } >
                <
                svg xmlns = "http://www.w3.org/2000/svg"
                viewBox = "0 0 24 24"
                fill = "currentColor"
                className = "h-6 w-6" >
                <
                path fillRule = "evenodd"
                d = "M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule = "evenodd" > < /path> < /
                svg > <
                span className = "block font-normal" > { isError ? 'Something went wrong' : 'Success' } < /span> < /
                div > <
                p className = { `mt-2 text-sm ${isError ? 'text-red-700' : 'text-green-700'}` } > { message } < /p> < /
                div >
            )
        }

        {
            isError && showInfoBox && ( <
                div className = "mt-4 p-4 bg-yellow-50 border-l-4 border-yellow-500 text-yellow-700 rounded-md animate-slide-in shadow-md" >
                <
                p >
                If you believe this is a mistake, please contact us on { ' ' } <
                a href = "https://discord.com/invite/83E9JuJj5K"
                className = "font-bold underline" >
                Discord <
                /a>{' '}
                and open a support ticket. <
                /p> < /
                div >
            )
        } <
        /div> <
        footer className = "mt-8 text-center" >
        Created by { ' ' } <
        a href = "https://alphaboyz.xyz/"
        className = "font-bold text-blue-600 hover:underline"
        target = "_blank"
        rel = "noopener noreferrer" >
        Alpha Boyz <
        /a> < /
        footer > <
        /div>
    );
};

export default WalletChecker;