import React from 'react';
import WalletChecker from './WalletChecker';

function App() {
    return ( <
        div className = "App" >
        <
        header className = "App-header" >
        <
        WalletChecker / >
        <
        /header> <
        /div>
    );
}

export default App;